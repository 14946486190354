import React, { useState, useEffect } from 'react'

import { DataStore, SortDirection } from '@aws-amplify/datastore';
import { HighScores, DailyHighScores, Games, Profiles } from '../models';
import * as helper from '../lib/AuthHelper'
import * as cookieLib from '../lib/CookieHelper'
import { possibleAchievements } from "./AchievementList"

const Gamelist = (props) => {

   const getFormattedEnumFromLastGame = (lastGame) => {
      if (lastGame === 'spaceFighter') {
         return Games.SPACEFIGHTER

      } else if (lastGame === 'blockBreaker') {
         return Games.BLOCKBREAKER

      } else if (lastGame === 'legMan') {
         return Games.LEGMAN

      } else if (lastGame === 'hungryKid') {
         return Games.HUNGRYKID

      } else if (lastGame === 'monsterSmasher') {
         return Games.MONSTERSMASHER

      } else if (lastGame === 'magicMan') {
         return Games.MAGICMAN

      } else if (lastGame === 'jumpMan') {
         return Games.JUMPMAN

      } else if (lastGame === 'lastSurvivor') {
         return Games.LASTSURVIVOR

      } else if (lastGame === 'stackers') {
         return Games.STACKERS

      }
   }

   const [currentHighScoreByClickedGame, setCurrentHighScoreByClickedGame] = useState(undefined)

   const getAchievementsByUser = () => {
      let achievementList = undefined
      DataStore.query(Profiles, p => p.userName.eq(props.userName)).then(userProfile => {
         if (userProfile[0] !== undefined) {
            achievementList = JSON.parse(userProfile[0].Achievements)
         }
         console.log(achievementList)

         if (achievementList === null) {
            console.log('achievement list is null')

            // if achievement list is null, this must be the first time a user is trying to access their achievements
            // set it to the base list
            achievementList = possibleAchievements

            // also update their profile with the correct list
            try {
               DataStore.query(Profiles, p => p.userName.eq(props.userName)).then(result => {
                  DataStore.save(Profiles.copyOf(result[0], updated => {
                     updated.Achievements = achievementList
                  }))
               })
               console.log('achievement list updated!')
            } catch (e) {
               console.log('error saving profile: ', e)
            }
         }
      }).catch(e => {
         console.log(e)
      }).finally(() => {
         console.log('achievementList', achievementList)
      })
   }

   const getAchievementsByGameAndUser = (gameTitle) => {
      let achievementList = undefined
      DataStore.query(Profiles, p => p.userName.eq(props.userName)).then(userProfile => {
         if (userProfile[0] !== undefined) {
            achievementList = JSON.parse(userProfile[0].Achievements)
         }

         if (achievementList === null) {
            console.log('achievement list is null')

            // if achievement list is null, this must be the first time a user is trying to access their achievements
            // set it to the base list
            achievementList = possibleAchievements

            // also update their profile with the correct list
            try {
               DataStore.query(Profiles, p => p.userName.eq(props.userName)).then(result => {
                  DataStore.save(Profiles.copyOf(result[0], updated => {
                     updated.Achievements = achievementList
                  }))
               })
               console.log('achievement list updated!')
            } catch (e) {
               console.log('error saving profile: ', e)
            }
         }

         // seperate full achievement list by gameTitle
         console.log('full achievement list: ', achievementList)
         let outerDict = achievementList
         Object.keys(outerDict).forEach(key => {
            if (key.toUpperCase() === gameTitle.toUpperCase()) {
               console.log('WE HAVE A MATCH MOTHERFUCKA')
               console.log('list of possible achievements: ', outerDict[key])
               setPossibleAchievementsOnClick(JSON.stringify(outerDict[key][0]))
            }
         })

      }).catch(e => {
         console.log(e)
      }).finally(() => {
         console.log('achievement list finished building!')
      })
   }

   // we always want to get daily high scores here
   const getHighScoresByGameAndUser = (gameTitle) => {
      getAchievementsByGameAndUser(gameTitle)
      let currentHighScore = 0

      DataStore.query(DailyHighScores, s => s.and((s) => [
         s.game.eq(getFormattedEnumFromLastGame(gameTitle)),
         s.user.eq(props.userName)
      ]), {
         sort: s => s.score(SortDirection.DESCENDING)

      }).then(result => {
         currentHighScore = result[0].score
         console.log('curent high score: ', currentHighScore)

         setCurrentHighScoreByClickedGame(currentHighScore ?? 0)
      }).finally(() => {
         /* Builds cookie store from result of above api query
            note: this is outside of the promise so even if the promise fails we still execute this logic
         */
         setActiveGameOnClick(gameTitle, currentHighScore ?? 0)
      })
   }

   /* The purpose of the active game cookie is to know which game to check high score when the main site is reloaded
      NOTE: The formatting of the cookie name has to be exact so we can reference these values later. camelCase of the games title is current standard
   */
   const setActiveGameOnClick = (gameTitle, currentHighScore) => {
      // first expire the currentGame cookie
      cookieLib.expireCookie('currentGame')
      // then set the current value
      cookieLib.setCookie('currentGame', gameTitle, 1)
      // next expire the currentHighScore cookie
      cookieLib.expireCookie('currentHighScore_' + gameTitle)
      // then set the current value
      cookieLib.setCookie('currentHighScore_' + gameTitle, currentHighScore, 1)
   }

   const setPossibleAchievementsOnClick = (achieveList) => {
      // first cookie is the list of possible achievements for each game
      // this is the achievement list that the game JS modifies
      cookieLib.expireCookie('achieveListByGame')
      cookieLib.setCookie('achieveListByGame', achieveList, 1)

      // the final cookie gives us the count of new achievements earned
      // this will be useful in the toast message to the user
      cookieLib.expireCookie('newAchievementCount')
      cookieLib.setCookie('newAchievementCount', 0, 1)
   }

   const setComputerPowerCookies = async (gameTitle) => {
      getAchievementsByGameAndUser(gameTitle)

      var currentLowLevel = 0
      var currentHighLevel = 0
      DataStore.query(Profiles, p => p.userName.eq(props.userName)).then(userProfile => {
         if (userProfile[0] !== undefined) {
            currentLowLevel = userProfile[0].ComputerPowerLevelMin
            currentHighLevel = userProfile[0].ComputerPowerLevelMax
         }
         console.log(currentLowLevel)
      }).catch(e => {
         console.log(e)
      }).finally(() => {
         
         // first expire the currentGame cookie
         cookieLib.expireCookie('currentGame')
         // then set the current value
         cookieLib.setCookie('currentGame', gameTitle, 1)

         // next expire the currentHighScore cookie
         cookieLib.expireCookie('currentHighLevel_' + gameTitle)
         // then set the current value
         cookieLib.setCookie('currentHighLevel_' + gameTitle, currentHighLevel, 1)

         // next expire the currentHighScore cookie
         cookieLib.expireCookie('currentLowLevel_' + gameTitle)
         // then set the current value
         cookieLib.setCookie('currentLowLevel_' + gameTitle, currentLowLevel, 1)
      })
   }

   return (
      <div className='row justify-content-center' style={{ textAlign: 'center', color: 'green', fontWeight: 'bold' }}>

         <h3 style={{ marginLeft: '50px' }}>Click on Any Game Card to Play!</h3>

         {/* Magic Man */}
         <a className='gameCard-wrapper' href='/MagicMan/MagicMan.html' onClick={() => getHighScoresByGameAndUser('magicMan')}>
            <div className='card-MagicMan gameCard-hover'>
               <div className='playLabel btn btn-success'>PLAY NOW!</div>
            </div>
         </a>

         {/* Last Survivor */}
         <a className='gameCard-wrapper' href='/LastSurvivor/LastSurvivor.html' onClick={() => getHighScoresByGameAndUser('lastSurvivor')}>
            <div className='card-LastSurvivor gameCard-hover'>
               <div className='playLabel btn btn-success'>PLAY NOW!</div>
            </div>
         </a>

         {/* Stackers */}
         <a className='gameCard-wrapper' href='/Stackers/Stackers.html' onClick={() => getHighScoresByGameAndUser('stackers')}>
            <div className='card-Stackers gameCard-hover'>
               <div className='playLabel btn btn-success'>PLAY NOW!</div>
            </div>
         </a>

         {/* Bow Master */}
         <a className='gameCard-wrapper' href='/BowMaster/BowMaster.html' onClick={() => console.log('clciked')}>
            <div className='card-BowMaster gameCard-hover'>
               <div className='playLabel btn btn-success'>PLAY NOW!</div>
            </div>
         </a>

         {/* Block Breaker */}
         <a className='gameCard-wrapper' href='/BlockBreaker/BlockBreaker.html' onClick={() => getHighScoresByGameAndUser('blockBreaker')}>
            <div className='card-BlockBreaker gameCard-hover'>
               <div className='playLabel btn btn-success'>PLAY NOW!</div>
            </div>
         </a>

         {/* Jump Man */}
         <a className='gameCard-wrapper' href='/JumpMan/JumpMan.html' onClick={() => getHighScoresByGameAndUser('jumpMan')}>
            <div className='card-JumpMan gameCard-hover'>
               <div className='playLabel btn btn-success'>PLAY NOW!</div>
            </div>
         </a>

         {/* Monster Smasher */}
         <a className='gameCard-wrapper' href='/MonsterSmasher/MonsterSmasher.html' onClick={() => getHighScoresByGameAndUser('monsterSmasher')}>
            <div className='card-MonsterSmasher gameCard-hover'>
               <div className='playLabel btn btn-success'>PLAY NOW!</div>
            </div>
         </a>


         {/* Space Fighter */}
         <a className='gameCard-wrapper' href='/SpaceFighter/SpaceFighter.html' onClick={() => getHighScoresByGameAndUser('spaceFighter')}>
            <div className='card-SpaceFighter gameCard-hover'>
               <div className='playLabel btn btn-success'>PLAY NOW!</div>
            </div>
         </a>

         {/* Leg Man */}
         <a className='gameCard-wrapper' href='/LegMan/LegMan.html' onClick={() => getHighScoresByGameAndUser('legMan')}>
            <div className='card-LegMan gameCard-hover'>
               <div className='playLabel btn btn-success'>PLAY NOW!</div>
            </div>
         </a>

         {/* Hungry Kid */}
         <a className='gameCard-wrapper' href='/HungryKid/HungryKid.html' onClick={() => getHighScoresByGameAndUser('hungryKid')}>
            <div className='card-HungryKid gameCard-hover'>
               <div className='playLabel btn btn-success'>PLAY NOW!</div>
            </div>
         </a>

         {/* Computer Power */}
         <a className='gameCard-wrapper' href='/ComputerPower/ComputerPower.html' onClick={() => setComputerPowerCookies('computerPower')}>
            <div className='card-ComputerPower gameCard-hover'>
               <div className='playLabel btn btn-success'>PLAY NOW!</div>
            </div>
         </a>

      </div>
   )
}

export default Gamelist
import React, { useEffect, useState } from "react"
import './HighScoreTable.css'
import * as cookieLib from '../lib/CookieHelper'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from "primereact/dropdown";
import { Button } from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton';
import { ToggleButton } from 'primereact/togglebutton';

/* Database import, this is it, really */
import { DataStore, SortDirection, Predicates } from '@aws-amplify/datastore';
import { HighScores, Games, Profiles, DailyHighScores } from "../models";

import toast, { Toaster } from 'react-hot-toast'

const HighScoreTable = (props) => {

   const [highScoreList, setHighScoreList] = useState([])
   const [computerPowerMaxList, setComputerPowerMaxList] = useState([])
   const [computerPowerMinList, setComputerPowerMinList] = useState([])

   const getHighScoresByGame = (game, isRefresh) => {

      if (dailyToggle) {
         /* Only ever pull back 100 items max */
         DataStore.query(DailyHighScores, s => s.and((s) => [
            s.game.eq(game)
         ]), {
            sort: s => s.score(SortDirection.DESCENDING),
            page: 0,
            limit: 100,
         }).then(result => {
            let listPlusRank = []
            for (let i = 0; i < result.length; i++) {
               let temp = { ...result[i] }
               temp['rank'] = '#' + (i + 1)

               let today = new Date()
               let dailyDate = new Date(temp.createdDate)
               dailyDate.setDate(dailyDate.getDate() + 1) // add 1 to daily date to keep hours / minutes / seconds out of calculation

               // if the daily high score is old, remove it
               if (today.getTime() > dailyDate.getTime()) {
                  DataStore.delete(result[i])
               } else {
                  // otherwise we are good to show it on the main page
                  listPlusRank.push(temp)
               }
            }

            setHighScoreList(listPlusRank)
         })
      } else {
         /* Only ever pull back 100 items max */
         DataStore.query(HighScores, s => s.and((s) => [
            s.game.eq(game)
         ]), {
            sort: s => s.score(SortDirection.DESCENDING),
            page: 0,
            limit: 100,
         }).then(result => {
            let listPlusRank = []
            for (let i = 0; i < result.length; i++) {
               let temp = { ...result[i] }
               temp['rank'] = '#' + (i + 1)

               listPlusRank.push(temp)
            }

            setHighScoreList(listPlusRank)
         })
      }


      if (isRefresh) {
         toast.success('High Scores Refreshed!')
      }
   }

   /* DO NOT CHANGE THIS ORDER
      - To save storage space, an array of strings is used for each game high score
      - This can be clever if we go off of the order of the Games enum on the AWS Backend environment
      - Translating that model object to an array was a headache, so a manual representation is below this comment
   */
   const gameListAccountScoreGeneration = [
      'SPACEFIGHTER',
      'BLOCKBREAKER',
      'LEGMAN',
      'HUNGRYKID',
      'MONSTERSMASHER',
      'MAGICMAN',
      'JUMPMAN',
      'LASTSURVIVOR',
      'STACKERS',
   ]

   /* Updates user's profile model with new account sore based on game list above
      this version also returns score to be "hot fixed" in the list as it gets built

      THIS IS OLD METHOD THAT DID NOT TAKE INTO ACCOUNT ACHIEVEMENT VALUES
      leaving it here incase I want to fix in the future. For now, the existing workflow is fine
   */
   const generateAccountScore = (username) => {
      let workingScore = 0
      let individualGameHighScores = []

      /* This iterates over the game list above, in order
         NOTE: I had to use toLowerCase to match the username saved on the high score record. wonder why it got to the db like that?
               The user's name in the profile table has the proper capitalization
      */
      for (let i = 0; i < gameListAccountScoreGeneration.length; i++) {
         let game = gameListAccountScoreGeneration[i]

         DataStore.query(HighScores, s => s.and((s) => [
            s.game.eq(game),
            s.user.eq(username)
         ]), {
            sort: s => s.score(SortDirection.DESCENDING),

         }).then(result => {

            if (result[0] !== undefined) {
               let currentHighScore = result[0].score

               /* Increment Account Score by each game's highes score (first result in a descending sort by score) */
               workingScore += currentHighScore
               individualGameHighScores.push(game + ', ' + currentHighScore.toString())
            }

         }).finally(() => {

            DataStore.query(Profiles, p => p.userName.eq(username)).then(result => {

               DataStore.save(Profiles.copyOf(result[0], updated => {
                  updated.lastGeneratedAccountScore = workingScore
                  updated.gameHighScores = individualGameHighScores
               }))
            })

            getAccountScores()
         })
      }
   }

   const getAccountScores = () => {

      DataStore.query(Profiles, Predicates.ALL, {
         sort: p => p.lastGeneratedAccountScore(SortDirection.DESCENDING),
      }).then(async result => {

         let listPlusRank = []
         for (let i = 0; i < result.length; i++) {
            let temp = { ...result[i] }
            temp['rank'] = '#' + (i + 1)


            if (temp.lastGeneratedAccountScore === '') {
               // that user needs to generate their account high score
               // this is old method that did not take into account achievement high scores
               //generateAccountScore(temp.userName)
            }

            listPlusRank.push(temp)
         }

         setHighScoreList(listPlusRank)
      })
   }

   const getComputerPowerLevels = () => {

      let maxRank = 0
      DataStore.query(Profiles, Predicates.ALL, {
         sort: p => p.ComputerPowerLevelMax(SortDirection.DESCENDING),
      }).then(async result => {

         let listPlusRankMaxPower = []
         for (let i = 0; i < result.length; i++) {
            let temp = { ...result[i] }
            if (temp['ComputerPowerLevelMax'] !== null && temp['ComputerPowerLevelMax'] !== "") {
               maxRank++
               temp['rank'] = '#' + maxRank
               listPlusRankMaxPower.push(temp)
            }
         }
         setComputerPowerMaxList(listPlusRankMaxPower)
      })

      let lowRank = 0
      DataStore.query(Profiles, Predicates.ALL, {
         sort: p => p.ComputerPowerLevelMin(SortDirection.ASCENDING),
      }).then(async result => {

         let listPlusRankMinPower = []
         for (let i = 0; i < result.length; i++) {
            let temp = { ...result[i] }

            if (temp['ComputerPowerLevelMin'] !== null && temp['ComputerPowerLevelMin'] !== "") {
               lowRank++
               temp['rank'] = '#' + lowRank
               listPlusRankMinPower.push(temp)
            }
         }

         setComputerPowerMinList(listPlusRankMinPower)
      })
   }

   // keep updated with cards you want to see high scores for
   const gameList = [
      { label: '-- Select --', value: 'start' },
      { label: 'Last Survivor', value: Games.LASTSURVIVOR },
      { label: 'Magic Man', value: Games.MAGICMAN },
      { label: 'Stackers', value: Games.STACKERS },
      { label: 'Jump Man', value: Games.JUMPMAN },
      { label: 'Block Breaker', value: Games.BLOCKBREAKER },
      { label: 'Monster Smasher', value: Games.MONSTERSMASHER },
      { label: 'Space Fighter', value: Games.SPACEFIGHTER },
      { label: 'Hungry Kid', value: Games.HUNGRYKID },
      { label: 'Leg Man', value: Games.LEGMAN },
   ]

   const [selectedGame, setSelectedGame] = useState('start')

   const HighScoreTitle = () => {
      if (selectValue === 'Game High Scores') {
         if (dailyToggle) {
            return "Today's Greatest!"
         } else {
            return "All Time Greatest!"
         }

      } else if (selectValue === 'Account High Scores') {
         return "gamechicken's Greatest!"
      } else if (selectValue === 'Computer Power') {
         return "Computer Power Level"
      }
   }

   const [dailyToggle, setDailyToggle] = useState(true)

   useEffect(() => {
      getHighScoresByGame(selectedGame)
   }, [dailyToggle])

   const tableHeader = () => {
      return (
         <div className="text-center">
            {selectValue === 'Game High Scores' &&
               <div className="dailyToggle">
                  <ToggleButton
                     checked={dailyToggle}
                     onChange={(e) => setDailyToggle(e.value)}
                     onIcon="pi pi-sun"
                     onLabel=""
                     offIcon="pi pi-hourglass"
                     offLabel=""
                  />
               </div>
            }
            <h2 style={{ marginLeft: '40px' }}><b>{HighScoreTitle()}</b></h2>
            <div className='grid-fluid gameDropDownPanel'>
               <div className="row">
                  {selectValue === 'Game High Scores' &&
                     <>
                        <div className="col-5 align-self-center mt-2">
                           <h4>Select a Game!</h4>
                        </div>
                        <div className="col-7">
                           <Dropdown value={selectedGame} options={gameList} filter onChange={(e) => {
                              setSelectedGame(e.value)
                              getHighScoresByGame(e.value)
                           }} />
                        </div>
                     </>
                  }

                  {selectValue === 'Account High Scores' &&
                     <>
                        <div className="col-12 d-flex justify-content-center align-self-center">
                           <p>The combined best high scores for each game on the site! Do you have what it takes?
                              <br /><br />
                              You can update your account score on your profile!
                           </p>
                        </div>
                     </>
                  }
                  {selectValue === 'Computer Power' &&
                     <>
                        <div className="col-12 d-flex justify-content-center align-self-center">
                           <p>
                              A test of a computer's strength
                           </p>
                        </div>
                        <div className="col-12 w-80 d-flex justify-content-center p-0">
                           <div className="selectButtonWrapper">
                              <SelectButton value={selectComputerPowerList} options={computerPowerListOptions} onChange={(e) => computerPowerSelectHandler(e.value)} />
                           </div>
                        </div>
                     </>
                  }

               </div>
            </div>
         </div>
      )
   }

   const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" onClick={() => {
      if (selectValue === 'Game High Scores') {
         getHighScoresByGame(selectedGame, true)
      } else if (selectValue === 'Account High Scores') {
         getAccountScores()
         toast.success('Account High Scores Refreshed!')
      } else if (selectValue === 'Computer Power') {
         getComputerPowerLevels()
         toast.success('Computer Power Levels Refreshed!')
      }
   }} />;

   const selectHandler = async (val) => {
      setSelectValue(val)

      if (val === 'Account High Scores') {
         setHighScoreList([])
         getAccountScores()
      } else if (val === 'Game High Scores') {
         setHighScoreList([])
         getHighScoresByGame(selectedGame)
      }
      else if (val === 'Computer Power') {
         setComputerPowerMaxList([])
         setComputerPowerMinList([])
         getComputerPowerLevels()
      }
   }

   const computerPowerSelectHandler = async (val) => {
      setSelectComputerPowerList(val)
   }


   const [selectValue, setSelectValue] = useState('Game High Scores');
   const options = ['Game High Scores', 'Account High Scores', 'Computer Power']

   const [selectComputerPowerList, setSelectComputerPowerList] = useState('Highest');
   const computerPowerListOptions = ['Highest', 'Lowest']

   return (
      <div className='grid-fluid highScoreRow'>
         <div className="row w-100 mt-1 mb-4 pl-0 pr-0">
            <div className="col-12 w-80 d-flex justify-content-center p-0">
               <div className="selectButtonWrapper">
                  <SelectButton value={selectValue} options={options} onChange={(e) => selectHandler(e.value)} />
               </div>
            </div>
         </div>
         <div className="row w-100 mt-2" style={{ position: 'relative' }}>
            <div className='col-12 d-flex justify-content-center'>
               {selectValue === 'Game High Scores' &&
                  <>
                     <DataTable
                        className="highScoreTable"
                        header={tableHeader}
                        value={highScoreList}
                        responsiveLayout={"scroll"}
                        resizableColumns={true}
                        autoLayout={true}
                        tableStyle={{ width: '400px' }}
                        paginator
                        paginatorPosition="top"
                        paginatorTemplate="CurrentPageReport PrevPageLink NextPageLink"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={5}
                        paginatorLeft={paginatorLeft}
                     >
                        <Column field="rank" header="Rank" style={{ fontWeight: 'bold' }} />
                        <Column field="user" header="Username" body={(row) => {
                           return (
                              <><a href='/ViewProfile' onClick={(e) => {
                                 // on click set cookie containing username to view
                                 // console.log below to see the username (all text) of the link we are clicking on
                                 //console.log(e.target.firstChild.data)

                                 // first expire the last viewUser, and then set the next
                                 // the component ViewProfile.js will read this cookie and use that username in all it's functions
                                 cookieLib.expireCookie('viewUser')
                                 cookieLib.setCookie('viewUser', e.target.firstChild.data)
                              }}>{row.user}</a></>
                           )
                        }} />
                        <Column field="score" header="Score" body={(row) => {
                           return (
                              <>{row.score.toLocaleString("en-US")}</>
                           )
                        }} />
                     </DataTable>
                  </>
               }

               {selectValue === 'Account High Scores' &&
                  <>
                     <DataTable
                        className="highScoreTable"
                        header={tableHeader}
                        value={highScoreList}
                        responsiveLayout={"scroll"}
                        resizableColumns={true}
                        autoLayout={true}
                        tableStyle={{ width: '400px' }}
                        paginator
                        paginatorPosition="top"
                        paginatorTemplate="CurrentPageReport PrevPageLink NextPageLink"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={5}
                        paginatorLeft={paginatorLeft}
                     >
                        <Column field="rank" header="Rank" style={{ fontWeight: 'bold' }} />
                        <Column field="userName" header="Username" body={(row) => {
                           return (
                              <><a href='/ViewProfile' onClick={(e) => {
                                 // on click set cookie containing username to view
                                 // console.log below to see the username (all text) of the link we are clicking on
                                 //console.log(e.target.firstChild.data)

                                 // first expire the last viewUser, and then set the next
                                 // the component ViewProfile.js will read this cookie and use that username in all it's functions
                                 cookieLib.expireCookie('viewUser')
                                 cookieLib.setCookie('viewUser', e.target.firstChild.data)
                              }}>{row.userName}</a></>
                           )
                        }} />
                        <Column field="lastGeneratedAccountScore" header="Score" body={(row) => {
                           return (
                              <>{row.lastGeneratedAccountScore.toLocaleString("en-US")}</>
                           )
                        }} />
                     </DataTable>
                  </>
               }

               {selectValue === 'Computer Power' && selectComputerPowerList === 'Highest' &&
                  <>
                     <DataTable
                        className="highScoreTable"
                        header={tableHeader}
                        value={computerPowerMaxList}
                        responsiveLayout={"scroll"}
                        resizableColumns={true}
                        autoLayout={true}
                        tableStyle={{ width: '400px' }}
                        paginator
                        paginatorPosition="top"
                        paginatorTemplate="CurrentPageReport PrevPageLink NextPageLink"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={5}
                        paginatorLeft={paginatorLeft}
                     >
                        <Column field="rank" header="Rank" style={{ fontWeight: 'bold' }} />
                        <Column field="userName" header="Username" body={(row) => {
                           return (
                              <><a href='/ViewProfile' onClick={(e) => {
                                 // on click set cookie containing username to view
                                 // console.log below to see the username (all text) of the link we are clicking on
                                 //console.log(e.target.firstChild.data)

                                 // first expire the last viewUser, and then set the next
                                 // the component ViewProfile.js will read this cookie and use that username in all it's functions
                                 cookieLib.expireCookie('viewUser')
                                 cookieLib.setCookie('viewUser', e.target.firstChild.data)
                              }}>{row.userName}</a></>
                           )
                        }} />
                        <Column field="ComputerPowerLevelMax" header="Power Level" />
                     </DataTable>
                  </>
               }
               {selectValue === 'Computer Power' && selectComputerPowerList === 'Lowest' &&
                  <>
                     <DataTable
                        className="highScoreTable"
                        header={tableHeader}
                        value={computerPowerMinList}
                        responsiveLayout={"scroll"}
                        resizableColumns={true}
                        autoLayout={true}
                        tableStyle={{ width: '400px' }}
                        paginator
                        paginatorPosition="top"
                        paginatorTemplate="CurrentPageReport PrevPageLink NextPageLink"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={5}
                        paginatorLeft={paginatorLeft}
                     >
                        <Column field="rank" header="Rank" style={{ fontWeight: 'bold' }} />
                        <Column field="userName" header="Username" />
                        <Column field="ComputerPowerLevelMin" header="Power Level" />
                     </DataTable>
                  </>
               }

            </div>
         </div>
         <Toaster />
      </div>
   )
}

export default HighScoreTable
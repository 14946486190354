import React, { useState, useEffect } from "react"
import NavBar from "./NavBar"
import * as helper from '../lib/AuthHelper'
import './MyProfile.css'
import './SignUp.css'
import { possibleAchievements } from "./AchievementList"
import * as cookieLib from '../lib/CookieHelper'

import toast, { Toaster } from 'react-hot-toast'

import { Auth } from 'aws-amplify';
import { DataStore, SortDirection } from '@aws-amplify/datastore';
import { HighScores, Profiles, Games } from '../models';

import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton';
import { Checkbox } from 'primereact/checkbox'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from "primereact/dropdown";

/* Complimentary Colors based on Main Navbar SVG color */
var DEPRECATED_backgroundColorMap = {
   0: '#CEDE9A',
   1: '#F69D7D',
   2: '#B4C2D3',
   3: '#FBAC75',
   4: '#AEBACC'
}

var backgroundColorMap_0 = {
   0: 'rgb(207, 181, 154)',
   1: 'rgb(191, 171, 141)',
   2: 'rgb(211, 195, 187)',
   3: 'rgb(191, 171, 153)',
   4: 'rgb(206, 188, 177)'
}

var backgroundColorMap_1 = {
   0: 'rgb(207, 181, 154)',
   1: 'rgb(191, 171, 141)',
   2: 'rgb(211, 195, 187)',
   3: 'rgb(191, 171, 153)',
   4: 'rgb(206, 188, 177)'
}

/* These SVG's I am switching by URL - this is probably the better way to do it, should refactor navbar's by downloading them from the dom maybe? */
/* The extra coding here gives a lot of dynamic content to the user */
var backgroundSVGMap_0 = {
   0: 'App-0-0',
   1: 'App-1-0',
   2: 'App-2-0',
   3: 'App-3-0',
   4: 'App-4-0',
}
var backgroundSVGMap_1 = {
   0: 'App-0-1',
   1: 'App-1-1',
   2: 'App-2-1',
   3: 'App-3-1',
   4: 'App-4-1',
}

let randomBGSVG = ''
let randomBGColor = ''
let navigateToAchievements = true

const ViewProfile = (props) => {

   const [isAuthenticated, setIsAuthenticated] = useState(false)
   const [currentUserName, setCurrentUserName] = useState(undefined)

   if (navigateToAchievements !== false) {
      // pull URL params out of URL in the event we need to navigate directly to achievements
      const url = new URL(window.location.href)
      navigateToAchievements = url.searchParams.get('achievements') === null ? false : true
   }

   /* Get Current User on Component Load */
   useEffect(() => {
      if (randomBGColor === '') {
         if (props.rngThemeIndexSwitch === 0) {
            randomBGColor = backgroundColorMap_0[props.themeIndex]
            randomBGSVG = backgroundSVGMap_0[props.themeIndex]
         } else {
            randomBGColor = backgroundColorMap_1[props.themeIndex]
            randomBGSVG = backgroundSVGMap_1[props.themeIndex]
         }

         document.body.style.background = randomBGColor
      }

      // next the viewUser from the browser
      let viewUser = cookieLib.getCookie('viewUser')

      setCurrentUserName(viewUser)
      getAchievementList(viewUser)

      let interval = setInterval(() => {
         // Check if Adsense script is loaded every 300ms
         if (currentUserName === undefined) {
            setSelectValue('High Scores')
            clearInterval(interval)
         }
      }, 500)

      return () => {
         clearInterval(interval)
      }

   }, [])

   const [accountScore, setAccountScore] = useState(0)
   const [loadingAccountScore, setLoadingAccountScore] = useState(false)

   /* DO NOT CHANGE THIS ORDER
      - To save storage space, an array of strings is used for each game high score
   */
   const gameList = [
      'SPACEFIGHTER',
      'BLOCKBREAKER',
      'LEGMAN',
      'HUNGRYKID',
      'MONSTERSMASHER',
      'MAGICMAN',
      'JUMPMAN',
      'LASTSURVIVOR'
   ]

   // this version is different than the profiles because it does not toast on completion
   // and it also does not save and update any profiles
   const getAccountScore = async (username) => {
      setLoadingAccountScore(true)

      var workingScore = 0
      let individualGameHighScores = []

      if (accountScore === 0) {
         /* This iterates over the game list above, in order */
         for (let i = 0; i < gameList.length; i++) {
            let game = gameList[i]

            DataStore.query(HighScores, s => s.and((s) => [
               s.game.eq(game),
               s.user.eq(username)
            ]), {
               sort: s => s.score(SortDirection.DESCENDING)

            }).then(result => {

               let currentHighScore = result[0].score
               let currentGame = result[0].game
               /* Increment Account Score by each game's highes score (first result in a descending sort by score) */
               workingScore += currentHighScore
               individualGameHighScores.push(currentGame + ', ' + currentHighScore.toString())

            }).finally(() => {
               // once the profile page loads, your achievements also get generated, and the value counted
               // we can take advantage of that and use that already calculated number here
               if (i === gameList.length - 1) {
                  workingScore += totalAchievementBonusPoints ?? 0
               }
               setAccountScore(workingScore)
               setLoadingAccountScore(false)
            }).catch(() => {})
         }
      }
   }

   const getAllGameHighScores = () => {
      DataStore.query(Profiles, p => p.userName.eq(currentUserName)).then(userProfile => {
         let gameHighScoreList = userProfile.gameHighScores

         if (userProfile[0] !== undefined) {
            gameHighScoreList = userProfile[0].gameHighScores

            // high scores come back like "SPACEFIGHTER, 360" in 1 row of the array
            // .split() on the comma returns a 2 element array, with the score portion at the end (1 position)
            try {
               if (gameHighScoreList && gameHighScoreList.length !== 0) {
                  //NOTE: the order is expected to match the order of the GAMES enum data model
                  setSpaceFighterHighScore(gameHighScoreList[0].split(',')[1] ?? 0)

               }
            } catch (e) {
               // this will throw if th euser does not have a high score for one of the games
            }

            try {
               if (gameHighScoreList && gameHighScoreList.length !== 0) {
                  //NOTE: the order is expected to match the order of the GAMES enum data model
                  setBlockBreakerHighScore(gameHighScoreList[1].split(',')[1] ?? 0)
               }
            } catch (e) {
               // this will throw if th euser does not have a high score for one of the games
            }

            try {
               if (gameHighScoreList && gameHighScoreList.length !== 0) {
                  //NOTE: the order is expected to match the order of the GAMES enum data model

                  setLegManHighScore(gameHighScoreList[2].split(',')[1] ?? 0)
               }
            } catch (e) {
               // this will throw if th euser does not have a high score for one of the games
            }

            try {
               if (gameHighScoreList && gameHighScoreList.length !== 0) {
                  //NOTE: the order is expected to match the order of the GAMES enum data model
                  setHungryKidHighScore(gameHighScoreList[3].split(',')[1] ?? 0)
               }
            } catch (e) {
               // this will throw if th euser does not have a high score for one of the games
            }

            try {
               if (gameHighScoreList && gameHighScoreList.length !== 0) {
                  //NOTE: the order is expected to match the order of the GAMES enum data model
                  setMonsterSmasherHighScore(gameHighScoreList[4].split(',')[1] ?? 0)
               }
            } catch (e) {
               // this will throw if th euser does not have a high score for one of the games
            }

            try {
               if (gameHighScoreList && gameHighScoreList.length !== 0) {
                  //NOTE: the order is expected to match the order of the GAMES enum data model
                  setMagicManHighScore(gameHighScoreList[5].split(',')[1] ?? 0)
               }
            } catch (e) {
               // this will throw if th euser does not have a high score for one of the games
            }

            try {
               if (gameHighScoreList && gameHighScoreList.length !== 0) {
                  //NOTE: the order is expected to match the order of the GAMES enum data model
                  setJumpManHighScore(gameHighScoreList[6].split(',')[1] ?? 0)
               }
            } catch (e) {
               // this will throw if th euser does not have a high score for one of the games
            }

            try {
               if (gameHighScoreList && gameHighScoreList.length !== 0) {
                  //NOTE: the order is expected to match the order of the GAMES enum data model
                  setLastSurvivorHighScore(gameHighScoreList[7].split(',')[1] ?? 0)
               }
            } catch (e) {
               // this will throw if th euser does not have a high score for one of the games
            }

         }
      }).catch(e => {
         console.log(e)
         toast.error('Something went wrong, oops! Try Again?')
      })
   }

   const getComputerPowerLevels = () => {
      DataStore.query(Profiles, p => p.userName.eq(currentUserName)).then(userProfile => {
         if (userProfile[0] !== undefined) {
            let computerPowerLevelMin = userProfile[0].ComputerPowerLevelMin
            let computerPowerLevelMax = userProfile[0].ComputerPowerLevelMax

            setComputerPowerLevelMin(computerPowerLevelMin)
            setComputerPowerLevelMax(computerPowerLevelMax)
         }
      }).catch(e => {
         console.log(e)
         toast.error('Something went wrong, oops! Try Again?')
      })
   }

   /* Individual High Score UseStates */
   const [spaceFighterHighScore, setSpaceFighterHighScore] = useState(0)
   const [blockBreakerHighScore, setBlockBreakerHighScore] = useState(0)
   const [legManHighScore, setLegManHighScore] = useState(0)
   const [hungryKidHighScore, setHungryKidHighScore] = useState(0)
   const [monsterSmasherHighScore, setMonsterSmasherHighScore] = useState(0)
   const [magicManHighScore, setMagicManHighScore] = useState(0)
   const [jumpManHighScore, setJumpManHighScore] = useState(0)
   const [lastSurvivorHighScore, setLastSurvivorHighScore] = useState(0)

   /** Computer Power Level Score UseStates */
   const [computerPowerLevelMin, setComputerPowerLevelMin] = useState()
   const [computerPowerLevelMax, setComputerPowerLevelMax] = useState()

   /* Form States */
   const [selectValue, setSelectValue] = useState('');
   const options = ['High Scores', 'Computer Power']

   useEffect(() => {
      getAllGameHighScores()
   }, [selectValue])

   const selectHandler = (val) => {
      setSelectValue(val)

      if (val === 'High Scores') {
         getAllGameHighScores()
      } else if (val === 'Computer Power') {
         getComputerPowerLevels()
      }
   }

   /* Achievements Set Up */
   const [activeTab, setActiveTab] = useState(navigateToAchievements ? 1 : 0)

   // create array from storage-friendly json
   const [achievementsArray_ViewProfile, setAchievementsArray_ViewProfile] = useState([])

   // keep updated with games you want to filter achievements for
   const dropdownGameList = [
      { label: '-- Filter By Game --', value: 'start' },
      { label: 'All Games', value: 'All' },
      { label: 'Last Survivor', value: 'LastSurvivor' },
      { label: 'Magic Man', value: 'MagicMan' },
      { label: 'Jump Man', value: 'JumpMan' },
      { label: 'Block Breaker', value: 'BlockBreaker' },
      { label: 'Monster Smasher', value: 'MonsterSmasher' },
      { label: 'Space Fighter', value: 'SpaceFighter' },
      { label: 'Hungry Kid', value: 'HungryKid' },
      { label: 'Leg Man', value: 'LegMan' },
      { label: 'Computer Power', value: 'ComputerPower' },
   ]

   // useState for dropdown filter
   const [selectedGame, setSelectedGame] = useState('All')
   const [achievementList_viewProfile, setAchievementList_viewProfile] = useState([])

   const [totalAchievements, setTotalAchievements] = useState()
   const [totalCompletedAchievements, setTotalCompletedAchievements] = useState()
   const [totalAchievementBonusPoints, setTotalAchievementBonusPoints] = useState()

   const updateAchievementsIfSet = async (incomingUserName) => {
      let lastGame = cookieLib.getCookie('currentGame')
      // this method gets called on page load, so the list might not exist yet
      // in that case do nothing
      if (cookieLib.getCookie('achieveListByGame') === "") {

         // if the cookie is empty, then the job is just to pull back the achievement list
         getAchievementList(incomingUserName)

         return
      }

      // otherise cookie is not empty (we did not return out), so we need to update the list with the changes, and save it
      DataStore.query(Profiles, p => p.userName.eq(incomingUserName)).then(userProfile => {
         let WIPlist = []
         if (userProfile[0] !== undefined) {
            WIPlist = JSON.parse(userProfile[0].Achievements[0])

            if (cookieLib.getCookie('achieveListByGame') === "") {
               return
            }
            let workingAchieveList = JSON.parse(cookieLib.getCookie('achieveListByGame'))
            let newAchievementCount = cookieLib.getCookie('newAchievementCount')

            // clean up current achievement cookies
            cookieLib.expireCookie('achieveListByGame')
            cookieLib.expireCookie('newAchievementCount')

            if (workingAchieveList !== undefined && newAchievementCount !== undefined) {
               if (newAchievementCount > 0) {
                  // incoming list is a partial list of that game
                  let outerDict = WIPlist
                  Object.keys(outerDict).forEach(key => {
                     if (key.toUpperCase() === lastGame.toUpperCase()) {
                        outerDict[key][0] = workingAchieveList
                     }
                  })
               }
            }
         }
      }).finally(() => {
         getAchievementList()
      })
   }

   useEffect(() => {
      // every time the achievements array changes, calculate our "progress" vars for the current state of the list
      let completedCount = 0
      let notCompletedCount = 0
      let totalAchievementBonusPoints = 0
      let skipThisGame = false
      Object.keys(achievementList_viewProfile).forEach(key => {
         if (selectedGame === 'All' || selectedGame.toUpperCase() === key.toUpperCase()) {
            skipThisGame = false
         } else {
            skipThisGame = true
         }

         Object.keys(achievementList_viewProfile[key][0]).forEach(achieve => {
            let currentAchievement = achievementList_viewProfile[key][0][achieve]
            if (!skipThisGame) {
               if (currentAchievement.isCompleted) {
                  completedCount++
                  totalAchievementBonusPoints += currentAchievement.value
               } else {
                  notCompletedCount++
               }
            }
         })
      })

      setTotalAchievementBonusPoints(totalAchievementBonusPoints)
      setTotalAchievements(completedCount + notCompletedCount)
      setTotalCompletedAchievements(completedCount)
   }, [achievementList_viewProfile])

   // every time our achievement bonus points get settled, recalculate account score
   useEffect(() => {
      if (currentUserName !== undefined) {
         getAccountScore(currentUserName)
      }
   }, [totalAchievementBonusPoints])

   const getAchievementList = (incomingUserName) => {
      let WIPlist = undefined
      if (incomingUserName === '' || incomingUserName === undefined) {
         return
      }
      DataStore.query(Profiles, p => p.userName.eq(incomingUserName)).then(userProfile => {
         if (userProfile[0] !== undefined) {
            try {
               WIPlist = JSON.parse(userProfile[0].Achievements[0])

            } catch (e) {
               return
            }

            // console.log('achievement list for user: ' + incomingUserName)
            // console.log('WIP List From DataStore', WIPlist)

            // I do not know why, but this is giving us an incorrect result at the end, ONLY for viewing other peoples profiles
            // this same exact routine works perfectly on the profile page, but here it is setting achievements to completed when they are not
            // I used the console.logs to the WIPList from datastore, then to check where it sets the completed value, and the final output
            // in my testing every single step was showing the correct value, and working correctly, except for the final list console.log after this
            // commented out routine. It would still include achievements that were not completed as completed, even when the output for that game
            // showed (from the routine) it was reading as not completed there

            // let newAchievementList_viewProfile = possibleAchievements
            // Object.keys(newAchievementList_viewProfile).forEach(key => {
            //    //console.log('new list value: ', newAchievementList_viewProfile[key])
            //    Object.keys(newAchievementList_viewProfile[key]).forEach(innerKey => {
            //       //console.log('inner value: ', newAchievementList_viewProfile[key][innerKey])
            //       Object.keys(newAchievementList_viewProfile[key][innerKey]).forEach(newSuperInnerKey => {
            //          let currentNewInnerAchievement = newAchievementList_viewProfile[key][innerKey][newSuperInnerKey]
            //          //console.log('new Super Inner value: ', newAchievementList_viewProfile[key][innerKey][newSuperInnerKey])

            //          // at this deep in the achievement list, we are now at each achievement level
            //          // at this point iterate over every possible achievement
            //          // find it's match (if it exists) and set the new lists's completed status accordingly

            //          /* ITERATE OVER EXISTING ACHIEVEMENT LIST (this may be null, if so that is okay) */
            //          Object.keys(WIPlist).forEach(newKey => {
            //             //console.log('existing list value: ', WIPlist[newKey])
            //             Object.keys(WIPlist[newKey]).forEach(newInnerKey => {
            //                //console.log('existing inner value: ', WIPlist[newKey][newInnerKey])
            //                Object.keys(WIPlist[newKey][newInnerKey]).forEach(superInnerKey => {
            //                   //console.log('super inner value: ', WIPlist[newKey][newInnerKey][superInnerKey])
            //                   let currentExistingInnerAchievement = WIPlist[newKey][newInnerKey][superInnerKey]
            //                   if (currentExistingInnerAchievement.AchieveName === currentNewInnerAchievement.AchieveName) {

            //                      currentNewInnerAchievement.isCompleted = currentExistingInnerAchievement.isCompleted

            //                      if (currentExistingInnerAchievement.className.includes('LegMan')) {
            //                         console.log('existing achieve ' + currentExistingInnerAchievement.AchieveName + ': ', currentExistingInnerAchievement.isCompleted)
            //                         console.log('--- new list achieve ' + currentNewInnerAchievement.AchieveName + ': ', currentNewInnerAchievement.isCompleted)
            //                      }
            //                   }
            //                })
            //             })
            //          })
            //       })
            //    })
            // })

            // console.log('finished combining with master list, result: ', WIPlist)
            setAchievementList_viewProfile(WIPlist)
         }
      }).catch(e => {
         console.log(e)
      }).finally(() => {
         //getAccountScore(incomingUserName)
      })
   }

   /* this builds the array of html which makes up the achievement page */
   useEffect(() => {

      // 1 is the achievements tab
      if (activeTab === 1) {
         let filteredArray = []
         let key = 0
         let skipThisGame = false
         let workingArray = []
         workingArray.push(achievementList_viewProfile)

         workingArray.map((game) => {
            Object.keys(game).forEach((achieveList) => {
               if (achieveList !== selectedGame && selectedGame !== 'All') {
                  skipThisGame = true
               } else {
                  skipThisGame = false
               }
               game[achieveList].forEach((innerList) => {
                  Object.keys(innerList).forEach((achievement) => {
                     if (!skipThisGame) {
                        //console.log(innerList[achievement].isCompleted + ', ' + JSON.stringify(innerList[achievement], null, 4))
                        filteredArray.push(
                           <div key={key++} className='col-4 d-flex justify-content-center pt-3'>
                              {/* COMPLETED ACHIEVEMENT STYLING */}
                              {innerList[achievement].isCompleted &&
                                 <div className="row achievementCard completedAchievementCard m2" style={{ position: 'relative' }}>
                                    <div className="col-8">
                                       <div className="row">
                                          <div className="col-12 d-flex justify-content-left">
                                             <h4>{innerList[achievement].AchieveName}</h4>
                                          </div>
                                          <div className="col-12 d-flex justify-content-left">
                                             <h5>Completed!</h5>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="col-4 justify-content-center">
                                       <div className="row d-flex justify-content-center">
                                          <div className="achievementValue">
                                             {innerList[achievement].value.toLocaleString("en-US")} points
                                          </div>
                                       </div>
                                       <div className="row">
                                          <div className={innerList[achievement].className}></div>
                                       </div>
                                    </div>
                                    {/* <div className="achievementValueGotten">
                                       {innerList[achievement].value} points
                                    </div> */}
                                 </div>
                              }

                              {/* NOT COMPLETED ACHIEVEMENT STYLING */}
                              {!innerList[achievement].isCompleted &&
                                 <div className="row achievementCard m2 d-flex" style={{ position: 'relative' }}>
                                    <div className="col-8">
                                       <div className="row">
                                          <div className="col-12 d-flex justify-content-left">
                                             <h4>{innerList[achievement].AchieveName}</h4>
                                          </div>
                                          <div className="col-12 d-flex justify-content-left pt-0">
                                             <h5 className="notCompletedText">Not Complete</h5>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="col-4">
                                       <div className="row">
                                          <div className="achievementValue">
                                             {innerList[achievement].value.toLocaleString("en-US")} points
                                          </div>
                                       </div>
                                       <div className="row">
                                          <div className={innerList[achievement].className}></div>
                                       </div>
                                    </div>
                                 </div>
                              }
                           </div>
                        )
                     }
                  })
               })
            })
         })

         setAchievementsArray_ViewProfile(filteredArray)
      }

   }, [activeTab, selectedGame, totalAchievements])

   return (
      <>
         <div className={randomBGSVG}>
            <NavBar backToHome={true} isAuthenticated={isAuthenticated} />
            <div className="grid-fluid h-100">
               <div className="row h-100">
                  {currentUserName &&
                     <>
                        <div className='col-12 w-75 h-100 tabView-wrapper'>
                           <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
                              <TabPanel header={currentUserName + "'s Profile"}>
                                 <h3>This is the Profile for --{'>'} {currentUserName}</h3>
                                 <div className="grid-fluid mt-3">
                                    <div className="row">
                                       <div className="col-6 d-flex justify-content-end align-self-center" style={{ position: 'relative' }}>
                                          <div className="row">
                                             <div className="col-12 d-flex justify-content-end align-self-center">
                                                <h2><b>Account Score:</b></h2>
                                             </div>
                                          </div>

                                       </div>
                                       <div className="col-6 d-flex justify-content-start align-self-center">
                                          <div className="row accountScoreWrapper">
                                             <div className="col-12">
                                                {!loadingAccountScore &&
                                                   <>
                                                      {accountScore.toLocaleString("en-US")}
                                                   </>
                                                }

                                                {loadingAccountScore &&
                                                   <>
                                                      ...
                                                   </>
                                                }
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                                 <div className='grid-fluid highScoreRow'>
                                    <div className="row w-100 mt-4 mb-4 pl-0 pr-0">
                                       <div className="col-12 mt-4 d-flex justify-content-center">
                                          <h4>Click to see game high scores, and computer power!</h4>
                                       </div>

                                       <div className="col-12 w-80 d-flex justify-content-center p-0">
                                          <div className="selectButtonWrapper">
                                             <SelectButton value={selectValue} options={options} onChange={(e) => selectHandler(e.value)} />
                                          </div>
                                       </div>
                                    </div>
                                    {/* VIEW HIGH SCORES */}
                                    {selectValue === 'High Scores' &&
                                       <>
                                          <div className="row w-100 mt-4">
                                             <div className="col-12 d-flex justify-content-center text-center">
                                                <h4>These have literally never been better! Seriously, these are their best scores!</h4>
                                             </div>

                                             <div className='col-4 d-flex justify-content-center pt-3'>
                                                <div className="row highScoreCard">
                                                   <div className="col-12 d-flex justify-content-center">
                                                      <h4>Space Fighter</h4>
                                                   </div>
                                                   <div className="col-12 d-flex justify-content-center">
                                                      <h5>{spaceFighterHighScore.toLocaleString("en-US")}</h5>
                                                   </div>
                                                </div>
                                             </div>

                                             <div className='col-4 d-flex justify-content-center pt-3'>
                                                <div className="row highScoreCard">
                                                   <div className="col-12 d-flex justify-content-center">
                                                      <h4>Block Breaker</h4>
                                                   </div>
                                                   <div className="col-12 d-flex justify-content-center">
                                                      <h5>{blockBreakerHighScore.toLocaleString("en-US")}</h5>
                                                   </div>
                                                </div>
                                             </div>

                                             <div className='col-4 d-flex justify-content-center pt-3'>
                                                <div className="row highScoreCard">
                                                   <div className="col-12 d-flex justify-content-center">
                                                      <h4>Leg Man</h4>
                                                   </div>
                                                   <div className="col-12 d-flex justify-content-center">
                                                      <h5>{legManHighScore.toLocaleString("en-US")}</h5>
                                                   </div>
                                                </div>
                                             </div>

                                             <div className='col-4 d-flex justify-content-center pt-3'>
                                                <div className="row highScoreCard">
                                                   <div className="col-12 d-flex justify-content-center">
                                                      <h4>Hungry Kid</h4>
                                                   </div>
                                                   <div className="col-12 d-flex justify-content-center">
                                                      <h5>{hungryKidHighScore.toLocaleString("en-US")}</h5>
                                                   </div>
                                                </div>
                                             </div>

                                             <div className='col-4 d-flex justify-content-center pt-3'>
                                                <div className="row highScoreCard">
                                                   <div className="col-12 d-flex justify-content-center">
                                                      <h4>Monster Smasher</h4>
                                                   </div>
                                                   <div className="col-12 d-flex justify-content-center">
                                                      <h5>{monsterSmasherHighScore.toLocaleString("en-US")}</h5>
                                                   </div>
                                                </div>
                                             </div>

                                             <div className='col-4 d-flex justify-content-center pt-3'>
                                                <div className="row highScoreCard">
                                                   <div className="col-12 d-flex justify-content-center">
                                                      <h4>Magic Man</h4>
                                                   </div>
                                                   <div className="col-12 d-flex justify-content-center">
                                                      <h5>{magicManHighScore.toLocaleString("en-US")}</h5>
                                                   </div>
                                                </div>
                                             </div>

                                             <div className='col-4 d-flex justify-content-center pt-3'>
                                                <div className="row highScoreCard">
                                                   <div className="col-12 d-flex justify-content-center">
                                                      <h4>Jump Man</h4>
                                                   </div>
                                                   <div className="col-12 d-flex justify-content-center">
                                                      <h5>{jumpManHighScore.toLocaleString("en-US")}</h5>
                                                   </div>
                                                </div>
                                             </div>

                                             <div className='col-4 d-flex justify-content-center pt-3'>
                                                <div className="row highScoreCard">
                                                   <div className="col-12 d-flex justify-content-center">
                                                      <h4>Last Survivor</h4>
                                                   </div>
                                                   <div className="col-12 d-flex justify-content-center">
                                                      <h5>{lastSurvivorHighScore.toLocaleString("en-US")}</h5>
                                                   </div>
                                                </div>
                                             </div>

                                          </div>
                                       </>
                                    }

                                    {/* COMPUTER POWER */}
                                    {selectValue === 'Computer Power' &&
                                       <>
                                          {/* CHANGE USERNAME FORM */}
                                          <div className="row -w-100 mt-4">
                                             {/* New row is here */}
                                             <div className="col-1 d-flex justify-content-center"></div>
                                             <div className='col-4 d-flex justify-content-center pt-3'>
                                                <div className="row highScoreCard">
                                                   <div className="col-12 d-flex justify-content-center text-center">
                                                      <h4>Highest Computer Power Level</h4>
                                                   </div>
                                                   <div className="col-12 d-flex justify-content-center">
                                                      <h5>{computerPowerLevelMax}</h5>
                                                   </div>
                                                </div>
                                             </div>
                                             <div className="col-2 d-flex justify-content-center"></div>
                                             <div className='col-4 d-flex justify-content-center pt-3'>
                                                <div className="row highScoreCard">
                                                   <div className="col-12 d-flex justify-content-center text-center">
                                                      <h4>Lowest Computer Power Level</h4>
                                                   </div>
                                                   <div className="col-12 d-flex justify-content-center">
                                                      <h5>{computerPowerLevelMin}</h5>
                                                   </div>
                                                </div>
                                             </div>
                                             <div className="col-1 d-flex justify-content-center"></div>
                                          </div>
                                       </>
                                    }
                                 </div>

                              </TabPanel>
                              <TabPanel header={currentUserName + "'s Achievements"}>
                                 <div className="row" style={{ position: 'relative', height: '100%' }}>
                                    <div className='totalAchievementBonusPoints'>
                                    </div>
                                    <div className="col-6 justify-content-left">
                                       <h3>*chicken noises*</h3>
                                    </div>
                                    <div className="col-6 d-flex justify-content-end">
                                       <Dropdown value={selectedGame} options={dropdownGameList} filter onChange={(e) => {
                                          setSelectedGame(e.value)
                                       }} />
                                    </div>
                                 </div>
                                 <div id='achievements'>
                                    <h4>Total Achievements {totalCompletedAchievements} / {totalAchievements}</h4>
                                    <h5>Total Achievement Bonus Points: {totalAchievementBonusPoints.toLocaleString('en-us')}</h5>
                                    <h5>{totalAchievements === totalCompletedAchievements ? 'Wow, All Achievements Complete!' : ''}</h5>
                                    <div className="row" style={{ height: '100%' }}>
                                       {achievementsArray_ViewProfile}
                                    </div>
                                 </div>
                              </TabPanel>
                           </TabView>
                        </div>
                        <div className='privacyPolicyWrapper'>
                           <a href='/PrivacyPolicy'>View Privacy Policy</a>
                        </div>
                     </>
                  }
               </div>
            </div>
         </div>
         <Toaster />
      </>
   )
}

export default ViewProfile
/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://pjkdz4ivanf3jadkecasdzv3mm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-vy3e64b3lraq3nwnoqrmmyzsbq",
    "aws_cognito_identity_pool_id": "us-east-1:7322bd09-ffca-46c2-93e1-6b1f70ca2be3",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_KH3bEdKbn",
    "aws_user_pools_web_client_id": "4uhd25viupjee0lp7ils8bjfpe",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "PREFERRED_USERNAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "Highscores-dev",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;

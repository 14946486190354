import React, { useEffect } from "react"
import NavBar from "./NavBar"

var backgroundColorMap_0 = {
   0: 'rgb(207, 181, 154)',
   1: 'rgb(191, 171, 141)',
   2: 'rgb(211, 195, 187)',
   3: 'rgb(191, 171, 153)',
   4: 'rgb(206, 188, 177)'
}

var backgroundColorMap_1 = {
   0: 'rgb(207, 181, 154)',
   1: 'rgb(191, 171, 141)',
   2: 'rgb(211, 195, 187)',
   3: 'rgb(191, 171, 153)',
   4: 'rgb(206, 188, 177)'
}

/* These SVG's I am switching by URL - this is probably the better way to do it, should refactor navbar's by downloading them from the dom maybe? */
/* The extra coding here gives a lot of dynamic content to the user */
var backgroundSVGMap_0 = {
   0: 'App-0-0',
   1: 'App-1-0',
   2: 'App-2-0',
   3: 'App-3-0',
   4: 'App-4-0',
}
var backgroundSVGMap_1 = {
   0: 'App-0-1',
   1: 'App-1-1',
   2: 'App-2-1',
   3: 'App-3-1',
   4: 'App-4-1',
}

let randomBGSVG = ''
let randomBGColor = ''

const PrivacyPolicy = (props) => {

   // theme useEffect
   useEffect(() => {
      if (randomBGColor === '') {
         // 50/50 chance to get either background svg map
         if (props.rngThemeIndexSwitch === 0) {
            randomBGColor = backgroundColorMap_0[props.themeIndex]
            randomBGSVG = backgroundSVGMap_0[props.themeIndex]
         } else {
            randomBGColor = backgroundColorMap_1[props.themeIndex]
            randomBGSVG = backgroundSVGMap_1[props.themeIndex]
         }

         document.body.style.background = randomBGColor
      }
   }, [])

   return (
      <>
         <div className={randomBGSVG}>
            <NavBar backToHome={true} />
            <div className="grid">
               <div className="row">
                  <div className="col-12 d-flex justify-content-center mt-4">
                     <h1>Privacy & Use Policy</h1>
                  </div>
                  <div className="col-1">

                  </div>
                  <div className="col-10 d-flex justify-content-center" style={{ margin: '20px' }}>
                     We want our users to always be aware of any information we collect, how we use it, and under what circumstances, if any, we disclose it.
                     <br />
                     <br />
                     We respect each site visitor's right to personal privacy. To that end, we collect and use information throughout our website only as disclosed in this Privacy Policy. This statement applies solely to information collected on this website. For each visitor to our website, our web server automatically recognizes no information regarding the domain or e-mail address.
                     <br />
                     <br />
                     We use information we collect to:
                  </div>
                  <div className="col-1">

                  </div>

                  <div className="col-1">

                  </div>
                  <div className="col-10 d-flex justify-content-center" style={{ margin: '10px' }}>
                     <ul>
                        <li>Improve the content of our Web page.</li>
                        <li>Track and update user high scores.</li>
                        <li>Track and update user achievement progress.</li>
                     </ul>
                  </div>
                  <div className="col-1">

                  </div>
                  <div className="col-1">

                  </div>
                  <div className="col-10 d-flex justify-content-center" style={{ margin: '20px' }}>

                     Account Registration is not required to enter our site, or play the games. However, access to certain services may require a user to complete a registration form.
                     We do show advertisement to our users, which is handled by Google Adsense. So any such concerns with our advertising, should be directed to them.
                     <br />
                     <br />
                     gamechicken.gg uses third-party advertising companies to serve ads when you visit our website. These companies may use information about your visits to this and other websites in order to provide advertisements about goods and services of interest to you.
                     <br />
                     <br />
                     Furthermore,
                     Google, as a third-party vendor, uses cookies to serve ads on this site.
                     Google's use of the DART cookie enables it and its partners to serve ads to your users based on their visit to your sites and/or other sites on the Internet.
                     Users may opt out of the use of the DART cookie by visiting the Google ad and content network privacy policy.
                     If we decide to change our privacy policy, we will post those changes to this privacy statement.
                     <br />
                     <br />
                     We are resolved to fixing disputes within 24-48 hours. If problems arise, please contact the domain admin.
                     <br />
                     <br />
                     For the benefit and enjoyment of all the gamers of gamechicken.gg,
                     We reserve the right to alter, delete, update, italicize, or bold any part of this privacy policy in the future.
                     We also reserve the right to alter, delete, or update any user high scores or user achievements in the future.
                     If you have any objections with that, please do not use any services provided by gamechicken.gg
                     <br />
                     <br />
                     Thank you,<br />
                     The GameChicken
                  </div>
                  <div className="col-1">

                  </div>

               </div>
            </div>
         </div>
      </>
   )
}

export default PrivacyPolicy
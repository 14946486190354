import * as EncryptionHelper from './EncryptionHelper'

/* Goal of this file to provide an interface to setting / updating cookies
   used to ferry information back to main react client. The flow looks like this:
      * user navigates to game
      * game creates any persistable data into the form of a cookie
      * user navigates back to main site (prompted by on screen canvas "new high score!" or something)
      * main site reads in any "expected" cookies
      * site makes amplify requests to persist cookie information
*/

export function setCookie(cname, cvalue, exdays) {
   const d = new Date();
   d.setTime(d.getTime() + (exdays*24*60*60*1000));
   let expires = "expires="+ d.toUTCString();
   let encryptedValue = EncryptionHelper.XORCipher.encode('31bTgdf323k4lndfske2bHd93k4RT', cvalue)
   document.cookie = cname + "=" + encryptedValue + ";" + expires + ";path=/" + ";secure";
}

export function expireCookie(cname) {
   const d = new Date('1/1/1900');
   let expires = "expires="+ d.toUTCString();
   document.cookie = cname + "=" + 'expired' + ";" + expires + ";path=/" + ";secure";
}

export function getCookie(cname) {
   let name = cname + "=";
   let decodedCookie = decodeURIComponent(document.cookie);
   let ca = decodedCookie.split(';');
   for(let i = 0; i <ca.length; i++) {
     let c = ca[i];
     while (c.charAt(0) == ' ') {
       c = c.substring(1);
     }
     if (c.indexOf(name) == 0) {
       return EncryptionHelper.XORCipher.decode('31bTgdf323k4lndfske2bHd93k4RT', c.substring(name.length, c.length));
     }
   }
   return "";
}
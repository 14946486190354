// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const Games = {
  "SPACEFIGHTER": "SPACEFIGHTER",
  "BLOCKBREAKER": "BLOCKBREAKER",
  "LEGMAN": "LEGMAN",
  "HUNGRYKID": "HUNGRYKID",
  "MONSTERSMASHER": "MONSTERSMASHER",
  "MAGICMAN": "MAGICMAN",
  "JUMPMAN": "JUMPMAN",
  "LASTSURVIVOR": "LASTSURVIVOR",
  "STACKERS": "STACKERS"
};

const { DailyHighScores, Profiles, HighScores } = initSchema(schema);

export {
  DailyHighScores,
  Profiles,
  HighScores,
  Games
};
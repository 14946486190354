import React, { useEffect, useState } from "react"
import { Auth } from 'aws-amplify';

import NavBar from "./NavBar"
import './SignUp.css' // yes this is the same css file as sign up
import '../App.scss'

// prime react components
import { InputText } from 'primereact/inputtext'
import { Password } from 'primereact/password'
import { Checkbox } from 'primereact/checkbox'

import toast, { Toaster } from 'react-hot-toast'

/* Complimentary Colors based on Main Navbar SVG color */
var DEPRECATED_backgroundColorMap = {
   0: '#CEDE9A',
   1: '#F69D7D',
   2: '#B4C2D3',
   3: '#FBAC75',
   4: '#AEBACC'
}

var backgroundColorMap_0 = {
   0: 'rgb(207, 181, 154)',
   1: 'rgb(191, 171, 141)',
   2: 'rgb(211, 195, 187)',
   3: 'rgb(191, 171, 153)',
   4: 'rgb(206, 188, 177)'
}

var backgroundColorMap_1 = {
   0: 'rgb(207, 181, 154)',
   1: 'rgb(191, 171, 141)',
   2: 'rgb(211, 195, 187)',
   3: 'rgb(191, 171, 153)',
   4: 'rgb(206, 188, 177)'
}

/* These SVG's I am switching by URL - this is probably the better way to do it, should refactor navbar's by downloading them from the dom maybe? */
/* The extra coding here gives a lot of dynamic content to the user */
var backgroundSVGMap_0 = {
   0: 'App-0-0',
   1: 'App-1-0',
   2: 'App-2-0',
   3: 'App-3-0',
   4: 'App-4-0',
}
var backgroundSVGMap_1 = {
   0: 'App-0-1',
   1: 'App-1-1',
   2: 'App-2-1',
   3: 'App-3-1',
   4: 'App-4-1',
}

let randomBGSVG = ''
let randomBGColor = ''

const LogIn = (props) => {

   const [email, setEmail] = useState('')
   const [password, setPassword] = useState('')
   const [isLogginIn, setIsLogginIn] = useState(false)
   const [isEmailNotConfirmedError, setIsEmailNotConfirmedError] = useState(false)

   useEffect(() => {
      if (randomBGColor === '') {
         // 50/50 chance to get either background svg map
         if (props.rngThemeIndexSwitch === 0) {
            randomBGColor = backgroundColorMap_0[props.themeIndex]
            randomBGSVG = backgroundSVGMap_0[props.themeIndex]
         } else {
            randomBGColor = backgroundColorMap_1[props.themeIndex]
            randomBGSVG = backgroundSVGMap_1[props.themeIndex]
         }

         document.body.style.background = randomBGColor
      }
   }, [])

   const handleKeyEvent = (event) => {
      console.log('enter event! ', event)
      if (event.which === 13) {
         handleLogIn()
      }
   }

   const handleLogIn = async () => {
      try {
         setIsLogginIn(true)
         const user = await Auth.signIn(email, password)
         console.log(user)
         toast.success('Logged in Successfully! Redirecting...', { duration: 3000 })

         // redirect after 2 seconds
         setIsLogginIn(false)
         setTimeout(() => {
            document.getElementById('backToHome').click()
         }, 2000)
      } catch (error) {
         setIsLogginIn(false)

         if (error.toString().includes('UserNotConfirmedException')) {
            setIsEmailNotConfirmedError(true)
         }
         toast.error('Something went wrong, oops! Try Again?')
      }
   }

   const [resetPasswordCheckbox, setResetPasswordCheckbox] = useState(false)
   const [phaseTwoPasswordReset, setPhaseTwoPasswordReset] = useState(false)

   const [newPasswordCode, setNewPasswordCode] = useState()
   const [newPassword, setNewPassword] = useState()

   const sendResetPasswordEmail = () => {
      try {
         Auth.forgotPassword(email)
         toast.success('Password Reset Email Sent!')
         setPhaseTwoPasswordReset(true)
      } catch (e) {
         toast.error('Something went wrong, oops! Try again?')
         console.log(e)
      }
   }

   const confirmPasswordReset = () => {
      try {
         Auth.forgotPasswordSubmit(email, newPasswordCode, newPassword).then((data) => console.log(data)).catch((err) => console.log(err))
         toast.success('New Password Confirmed')
         setPhaseTwoPasswordReset(false)
         setResetPasswordCheckbox(false)
      } catch (e) {
         toast.error('Something went wrong, oops! Try again?')
         console.log(e)
      }
   }

   return (
      <>
         <div className={randomBGSVG}>
            <NavBar backToHome={true} />

            <div className='grid-fluid signUpGrid d-flex justify-content-center'>
               <div className='row registerDialog'>
                  <div className='col-12 d-flex justify-content-center mb-3 border-bottom'>
                     <h2>Log In!</h2>
                  </div>

                  <div className="col-12 mt-0 mb-3 d-flex justify-content-center">
                     <Checkbox id='checkbox' className='checkbox' onChange={e => setResetPasswordCheckbox(e.checked)} checked={resetPasswordCheckbox} />
                     <label htmlFor="checkbox" className="p-checkbox-label checkbox-text">Forgot Password?</label>
                  </div>

                  {/* RESET PASSWORD FORM */}
                  {resetPasswordCheckbox &&
                     <>
                        {phaseTwoPasswordReset &&
                           <>
                              <p className="text-center">Enter the verification code sent to your email below, along with your new password!</p>
                              <div className="col-12 d-flex justify-content-center mt-3 mb-3">
                                 <span className="p-float-label">
                                    <InputText id='email' className='register-form-button' value={newPasswordCode} onChange={(e) => setNewPasswordCode(e.target.value)} />
                                    <label htmlFor="email">Verification Code</label>
                                 </span>
                              </div>

                              <div className="col-12 d-flex justify-content-center mt-3 mb-3">
                                 <span className="p-float-label">
                                    <Password toggleMask  id='password' className='register-form-button' inputStyle={{ width: "100%" }} style={{ width: "100%" }} value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                                    <label htmlFor="password">New Password</label>
                                 </span>
                              </div>

                              <div className='col-12 d-flex justify-content-center mt-4 pt-3'>
                                 <a className='signup-button text-center' onClick={confirmPasswordReset}>Confirm New Password</a>
                              </div>
                           </>
                        }

                        {!phaseTwoPasswordReset &&
                           <>
                              <p className="text-center">Enter your account email below, and we'll send a code to that email. From there, we can get you a new password!</p>
                              <div className="col-12 d-flex justify-content-center mt-3 mb-3">
                                 <span className="p-float-label">
                                    <InputText id='email' className='register-form-button' value={email} onChange={(e) => setEmail(e.target.value)} />
                                    <label htmlFor="email">Email</label>
                                 </span>
                              </div>

                              <div className='col-12 d-flex justify-content-center mt-4 pt-3'>
                                 <a className='signup-button text-center' onClick={sendResetPasswordEmail}>Send It!</a>
                              </div>
                           </>
                        }
                     </>
                  }

                  {/* NORMAL LOGIN */}
                  {!resetPasswordCheckbox &&
                     <>
                        <div className="col-12 d-flex justify-content-center mt-3 mb-3">
                           <span className="p-float-label">
                              <InputText id='email' className='register-form-button' onKeyDown={(e) => handleKeyEvent(e)} value={email} onChange={(e) => setEmail(e.target.value)} />
                              <label htmlFor="email">Email</label>
                           </span>
                        </div>

                        <div className="col-12 d-flex justify-content-center mt-3 mb-3">
                           <span className="p-float-label">
                              <Password toggleMask  id='password' className='register-form-button' onKeyDown={(e) => handleKeyEvent(e)} inputStyle={{ width: "100%" }} style={{ width: "100%" }} value={password} onChange={(e) => setPassword(e.target.value)} />
                              <label htmlFor="password">Password</label>
                           </span>
                        </div>

                        {isEmailNotConfirmedError &&
                           <>
                              <div className="col-12 d-flex justify-content-center mt-3 mb-3 text-center">
                                 <p>Woah, your email is totally not confirmed. <br /><br /> You need to use an email you have access to, otherwise you might never be able to recover your account
                                    if you were to do something dumb, like forget your password or something. So confirm your email!
                                 </p>
                              </div>

                              <div className="col-12 d-flex justify-content-center mt-3 mb-3 text-center">
                                 <a href='/SignUp?confirm=true'>Confirm Your Email</a>
                              </div>
                           </>
                        }

                        <div className='col-12 d-flex justify-content-center mt-4 pt-3'>
                           {isLogginIn &&
                              <a className='signup-button-disabled text-center'>Logging in...</a>
                           }

                           {!isLogginIn &&
                              <a className='signup-button text-center' onClick={handleLogIn}>Log In!</a>
                           }
                        </div>
                     </>
                  }
               </div>
            </div>

         </div>
         <Toaster />
      </>
   )

}

export default LogIn
export const possibleAchievements = {
   LegMan: [{
      FirstAchievement: { AchieveName: 'Play Leg Man', isCompleted: false, className: 'playLegManAchievement', value: 10 },
      SecondAchievement: { AchieveName: 'Reach Level 5', isCompleted: false, className: 'playLegManAchievement', value: 200 },
      ThirdAchievement: { AchieveName: 'Reach Level 10', isCompleted: false, className: 'playLegManAchievement', value: 500 },
      FourthAchievement: { AchieveName: 'Reach Level 20', isCompleted: false, className: 'playLegManAchievement', value: 5000 },
      FifthAchievement: { AchieveName: 'Get Lucky 3 Times In A Row', isCompleted: false, className: 'playLegManAchievement', value: 3000 },
      SixthAchievement: { AchieveName: 'Get UnLucky 5 Times In A Row', isCompleted: false, className: 'playLegManAchievement', value: 10000 },
      SeventhAchievement: { AchieveName: 'Spacebar Rolls the Die??', isCompleted: false, className: 'playLegManAchievement', value: 500 },
      EightAchievement: { AchieveName: 'Click Roll Die for the First Time', isCompleted: false, className: 'playLegManAchievement', value: 20 },
      NinthAchievement: { AchieveName: 'Wait, The Walls Hurt?', isCompleted: false, className: 'playLegManAchievement', value: 50 },
   }],
   HungryKid: [{
      FirstAchievement: { AchieveName: 'Play Hungry Kid', isCompleted: false, className: 'playHungryKidAchievement', value: 10 },
      SecondAchievement: { AchieveName: 'Eat 10 Strawberries in One Game', isCompleted: false, className: 'playHungryKidAchievement', value: 200 },
      ThirdAchievement: { AchieveName: 'Eat 10 Apples in One Game', isCompleted: false, className: 'playHungryKidAchievement', value: 200 },
      FourthAchievement: { AchieveName: 'Eat 10 Bananas in One Game', isCompleted: false, className: 'playHungryKidAchievement', value: 200 },
      FifthAchievement: { AchieveName: 'Eat 20 Strawberries in One Game', isCompleted: false, className: 'playHungryKidAchievement', value: 2000 },
      SixthAchievement: { AchieveName: 'Eat 20 Apples in One Game', isCompleted: false, className: 'playHungryKidAchievement', value: 2000 },
      SeventhAchievement: { AchieveName: 'Eat 20 Bananas in One Game', isCompleted: false, className: 'playHungryKidAchievement', value: 2000 },
      EighthAchievement: { AchieveName: 'Bombs Hurt! Ow!', isCompleted: false, className: 'playHungryKidAchievement', value: 500 },
      NinthAchievement: { AchieveName: 'Reach a Perfect Streak of 10', isCompleted: false, className: 'playHungryKidAchievement', value: 5000 },
      TenthAchievement: { AchieveName: 'Reach a Perfect Streak of 25', isCompleted: false, className: 'playHungryKidAchievement', value: 10000 },
      EleventhAchievement: { AchieveName: 'Reach a Perfect Streak of 50!!', isCompleted: false, className: 'playHungryKidAchievement', value: 25000 },
   }],
   SpaceFighter: [{
      FirstAchievement: { AchieveName: 'Play Space Fighter', isCompleted: false, className: 'playSpaceFighterAchievement', value: 10 },
      SecondAchievement: { AchieveName: 'Clear World 1', isCompleted: false, className: 'playSpaceFighterAchievement', value: 500 },
      ThirdAchievement: { AchieveName: 'Clear World 2', isCompleted: false, className: 'playSpaceFighterAchievement', value: 1000 },
      FourthAchievement: { AchieveName: 'Clear World 3', isCompleted: false, className: 'playSpaceFighterAchievement', value: 2000 },
      FifthAchievement: { AchieveName: 'Clear All 3 Worlds on Hard In One Run!!', isCompleted: false, className: 'playSpaceFighterAchievement', value: 100000 },
      SixthAchievement: { AchieveName: 'Space Fighting Is Hard (Get Blown Up)', isCompleted: false, className: 'playSpaceFighterAchievement', value: 500 },
      SeventhAchievement: { AchieveName: 'Defeat 50 Enemies In One Run', isCompleted: false, className: 'playSpaceFighterAchievement', value: 1000 },
      EighthAchievement: { AchieveName: 'Defeat 100 Enemies In One Run', isCompleted: false, className: 'playSpaceFighterAchievement', value: 5000 },
      NinthAchievement: { AchieveName: 'Defeat 250 Enemies In One Run', isCompleted: false, className: 'playSpaceFighterAchievement', value: 10000 },
   }],
   BlockBreaker: [{
      FirstAchievement: { AchieveName: 'Play Block Breaker', isCompleted: false, className: 'playBlockBreakerAchievement', value: 10 },
      SecondAchievement: { AchieveName: 'Try Fun Mode!', isCompleted: false, className: 'playBlockBreakerAchievement', value: 500 },
      ThirdAchievement: { AchieveName: 'Bombs? Those Stun Me??', isCompleted: false, className: 'playBlockBreakerAchievement', value: 1000 },
      FourthAchievement: { AchieveName: 'Have 3 Extra Balls On the Screen At One Time (Hard Mode Only)', isCompleted: false, className: 'playBlockBreakerAchievement', value: 2000 },
      FifthAchievement: { AchieveName: 'Have 5 Extra Balls On the Screen At One Time (Hard Mode Only)', isCompleted: false, className: 'playBlockBreakerAchievement', value: 5000 },
      SixthAchievement: { AchieveName: 'Have 10 Extra Balls On the Screen At One Time (Hard Mode Only)', isCompleted: false, className: 'playBlockBreakerAchievement', value: 20000 },
      SeventhAchievement: { AchieveName: 'DOUBLE POINTS ROCK', isCompleted: false, className: 'playBlockBreakerAchievement', value: 500 },
      EigthAchievement: { AchieveName: 'See What is Inside an Item Block', isCompleted: false, className: 'playBlockBreakerAchievement', value: 500 },
      NinthAchievement: { AchieveName: 'See 3 Backgrounds In One Run (Hard Mode Only)', isCompleted: false, className: 'playBlockBreakerAchievement', value: 1000 },
      TenthAchievement: { AchieveName: 'See 5 Backgrounds In One Run (Hard Mode Only)', isCompleted: false, className: 'playBlockBreakerAchievement', value: 2000 },
      EleventhAchievement: { AchieveName: 'See 10 Backgrounds In One Run (Hard Mode Only)', isCompleted: false, className: 'playBlockBreakerAchievement', value: 5000 },
      TwelfthAchievement: { AchieveName: 'See 20 Backgrounds In One Run (Hard Mode Only)', isCompleted: false, className: 'playBlockBreakerAchievement', value: 25000 },
   }],
   MagicMan: [{
      FirstAchievement: { AchieveName: 'Play Magic Man', isCompleted: false, className: 'playMagicManAchievement', value: 10 },
      SecondAchievement: { AchieveName: 'Pick Up An Item', isCompleted: false, className: 'playMagicManAchievement', value: 200 },
      ThirdAchievement: { AchieveName: 'Woah! A Treasure Chest?', isCompleted: false, className: 'playMagicManAchievement', value: 500 },
      FourthAchievement: { AchieveName: 'Gold Chests Rule!', isCompleted: false, className: 'playMagicManAchievement', value: 2000 },
      FifthAchievement: { AchieveName: 'Find the Shop', isCompleted: false, className: 'playMagicManAchievement', value: 500 },
      SixthAchievement: { AchieveName: 'Find the Boss', isCompleted: false, className: 'playMagicManAchievement', value: 500 },
      SeventhAchievement: { AchieveName: 'Where Did All My Keys Go?', isCompleted: false, className: 'playMagicManAchievement', value: 1000 },
      EigthAchievement: { AchieveName: 'Go Bomb Crazy', isCompleted: false, className: 'playMagicManAchievement', value: 1000 },
      NinthAchievement: { AchieveName: 'Beat A Boss', isCompleted: false, className: 'playMagicManAchievement', value: 5000 },
      TenthAchievement: { AchieveName: 'Reach The Castle Block', isCompleted: false, className: 'playMagicManAchievement', value: 7000 },
      EleventhAchievement: { AchieveName: 'Reach The Kings Chambers', isCompleted: false, className: 'playMagicManAchievement', value: 10000 },
      TwelfthAchievement: { AchieveName: 'Win The Game!', isCompleted: false, className: 'playMagicManAchievement', value: 25000 },
   }],
   JumpMan: [{
      FirstAchievement: { AchieveName: 'Play Jump Man', isCompleted: false, className: 'playJumpManAchievement', value: 10 },
      SecondAchievement: { AchieveName: 'Jump To A Platform', isCompleted: false, className: 'playJumpManAchievement', value: 200 },
      ThirdAchievement: { AchieveName: 'Reach 5000 Points', isCompleted: false, className: 'playJumpManAchievement', value: 1000 },
      FourthAchievement: { AchieveName: 'Reach 10000 Points', isCompleted: false, className: 'playJumpManAchievement', value: 5000 },
      FifthAchievement: { AchieveName: 'Reach 20000 Points', isCompleted: false, className: 'playJumpManAchievement', value: 10000 },
      SixthAchievement: { AchieveName: 'Reach 50000 Points', isCompleted: false, className: 'playJumpManAchievement', value: 25000 },
      SeventhAchievement: { AchieveName: 'Faaaaaalling!', isCompleted: false, className: 'playJumpManAchievement', value: 1000 },
      EighthAchievement: { AchieveName: '100% Jump Power? Wow!', isCompleted: false, className: 'playJumpManAchievement', value: 5000 },
      TenthAchievement: { AchieveName: 'Survive After 5 Consecutive Minumum Power Jumps', isCompleted: false, className: 'playJumpManAchievement', value: 20000 },
      EleventhAchievement: { AchieveName: 'Survive After 5 Consecutive Max Power Jumps', isCompleted: false, className: 'playJumpManAchievement', value: 5000 },
   }],
   LastSurvivor: [{
      FirstAchievement: { AchieveName: 'Play Last Survivor', isCompleted: false, className: 'playLastSurvivorAchievement', value: 10 },
      SecondAchievement: { AchieveName: 'Discover Homing Orb', isCompleted: false, className: 'playLastSurvivorAchievement', value: 1200 },
      ThirdAchievement: { AchieveName: 'Discover Slash', isCompleted: false, className: 'playLastSurvivorAchievement', value: 1200 },
      FourthAchievement: { AchieveName: 'Discover Fireball', isCompleted: false, className: 'playLastSurvivorAchievement', value: 1200 },
      FifthAchievement: { AchieveName: 'Discover Arcane Circle', isCompleted: false, className: 'playLastSurvivorAchievement', value: 1200 },
      SixthAchievement: { AchieveName: 'Discover Shuriken', isCompleted: false, className: 'playLastSurvivorAchievement', value: 1200 },
      SeventhAchievement: { AchieveName: 'Upgrade Homing Orb to Lvl. 5', isCompleted: false, className: 'playLastSurvivorAchievement', value: 10000 },
      EighthAchievement: { AchieveName: 'Upgrade Slash to Lvl. 5', isCompleted: false, className: 'playLastSurvivorAchievement', value: 10000 },
      TenthAchievement: { AchieveName: 'Upgrade Fireball to Lvl. 5', isCompleted: false, className: 'playLastSurvivorAchievement', value: 10000 },
      EleventhAchievement: { AchieveName: 'Upgrade Arcane Circle to Lvl. 5', isCompleted: false, className: 'playLastSurvivorAchievement', value: 10000 },
      TwelvthAchievement: { AchieveName: 'Upgrade Shuriken to Lvl. 5', isCompleted: false, className: 'playLastSurvivorAchievement', value: 10000 },
      ThirteenthAchievement: { AchieveName: 'Reach Level 10', isCompleted: false, className: 'playLastSurvivorAchievement', value: 5000 },
      FourteenthAchievement: { AchieveName: 'Reach Level 20', isCompleted: false, className: 'playLastSurvivorAchievement', value: 15000 },
      FifteenthAchievement: { AchieveName: 'Reach Level 30', isCompleted: false, className: 'playLastSurvivorAchievement', value: 20000 },
      SixteenthAchievement: { AchieveName: 'Reach Level 50', isCompleted: false, className: 'playLastSurvivorAchievement', value: 50000 },
      SeventeenthAchievement: { AchieveName: 'Upgrade Shotgun to Lvl. 5', isCompleted: false, className: 'playLastSurvivorAchievement', value: 10000 },
      EighteenthAchievement: { AchieveName: 'Upgrade Grenade to Lvl. 5', isCompleted: false, className: 'playLastSurvivorAchievement', value: 10000 },
      NineteenthAchievement: { AchieveName: 'Discover Grenade', isCompleted: false, className: 'playLastSurvivorAchievement', value: 1200 },
      TwentyAchievement: { AchieveName: 'Discover Shotgun', isCompleted: false, className: 'playLastSurvivorAchievement', value: 1200 },
      TwentyFirstAchievement: { AchieveName: 'Discover Sniper', isCompleted: false, className: 'playLastSurvivorAchievement', value: 1200 },
      TwentySecondAchievement: { AchieveName: 'Upgrade Sniper to Lvl. 5', isCompleted: false, className: 'playLastSurvivorAchievement', value: 10000 },
      TwentyThirdAchievement: { AchieveName: 'Upgrade Energy Shot to Lvl. 5', isCompleted: false, className: 'playLastSurvivorAchievement', value: 10000 },
      TwentyFourthAchievement: { AchieveName: 'Charge a Crystal!', isCompleted: false, className: 'playLastSurvivorAchievement', value: 2000 },
      TwentyFifthAchievement: { AchieveName: 'Charge 3 Crystals in One Run', isCompleted: false, className: 'playLastSurvivorAchievement', value: 15000 },
      TwentySixthAchievement: { AchieveName: 'Charge 12 Crystals in One Run', isCompleted: false, className: 'playLastSurvivorAchievement', value: 35000 },
      TwentySeventhAchievement: { AchieveName: 'Charge 24 Crystals in One Run', isCompleted: false, className: 'playLastSurvivorAchievement', value: 50000 },
      TwentyEightAchievement: { AchieveName: 'Discover Energy Shot', isCompleted: false, className: 'playLastSurvivorAchievement', value: 2000 },
      TwentyNinthAchievement: { AchieveName: 'Discover M15', isCompleted: false, className: 'playLastSurvivorAchievement', value: 2000 },
      ThirtiethAchievement: { AchieveName: 'Upgrade M15 to Lvl. 5', isCompleted: false, className: 'playLastSurvivorAchievement', value: 10000 },
   }],
   MonsterSmasher: [{
      FirstAchievement: { AchieveName: 'Play Monster Smasher', isCompleted: false, className: 'playMonsterSmasherAchievement', value: 10 },
      SecondAchievement: { AchieveName: 'Fastest Smash', isCompleted: false, className: 'playMonsterSmasherAchievement', value: 10000 },
      ThirdAchievement: { AchieveName: 'Slowest Smash', isCompleted: false, className: 'playMonsterSmasherAchievement', value: 1000 },
      FourthAchievement: { AchieveName: 'You are too slow', isCompleted: false, className: 'playMonsterSmasherAchievement', value: 500 },
      FifthAchievement: { AchieveName: 'Smash a Vampire', isCompleted: false, className: 'playMonsterSmasherAchievement', value: 1000 },
      SixthAchievement: { AchieveName: 'Smash a Ghost', isCompleted: false, className: 'playMonsterSmasherAchievement', value: 500 },
      SeventhAchievement: { AchieveName: 'Smash a Skeleton', isCompleted: false, className: 'playMonsterSmasherAchievement', value: 250 },
      EighthAchievement: { AchieveName: 'Smash a Zombie', isCompleted: false, className: 'playMonsterSmasherAchievement', value: 100 },
      NinethAchievement: { AchieveName: 'End with a Negative Score?', isCompleted: false, className: 'playMonsterSmasherAchievement', value: 1337 },
   }],
   ComputerPower: [{
      FirstAchievement: { AchieveName: 'Generate Computer Power Score', isCompleted: false, className: 'playComputerPowerAchievement', value: 500 },
      SecondAchievement: { AchieveName: 'Doo Doo Computer', isCompleted: false, className: 'playComputerPowerAchievement', value: 1000 },
      ThirdAchievement: { AchieveName: 'Toaster Computer', isCompleted: false, className: 'playComputerPowerAchievement', value: 2000 },
      FourthAchievement: { AchieveName: 'Old Computer', isCompleted: false, className: 'playComputerPowerAchievement', value: 3000 },
      FifthAchievement: { AchieveName: 'Normie Computer', isCompleted: false, className: 'playComputerPowerAchievement', value: 4000 },
      SixthAchievement: { AchieveName: 'Good Computer', isCompleted: false, className: 'playComputerPowerAchievement', value: 5000 },
      SevenethAchievement: { AchieveName: 'Super Computer', isCompleted: false, className: 'playComputerPowerAchievement', value: 7500 },
      EighthAchievement: { AchieveName: 'Flaming Computer', isCompleted: false, className: 'playComputerPowerAchievement', value: 10000 },
   }],
   Stackers: [{
      FirstAchievement: { AchieveName: 'Play Stackers', isCompleted: false, className: 'playStackersAchievement', value: 10 },
      SecondAchievement: { AchieveName: 'Make a 5 Stack Tower', isCompleted: false, className: 'playStackersAchievement', value: 1000 },
      ThirdAchievement: { AchieveName: 'Make a 10 Stack Tower', isCompleted: false, className: 'playStackersAchievement', value: 2000 },
      FourthAchievement: { AchieveName: 'Make a 15 Stack Tower', isCompleted: false, className: 'playStackersAchievement', value: 3000 },
      FifthAchievement: { AchieveName: 'Make a 20 Stack Tower', isCompleted: false, className: 'playStackersAchievement', value: 5000 },
      SixthAchievement: { AchieveName: 'Make a 40 Stack Tower?', isCompleted: false, className: 'playStackersAchievement', value: 25000 },
      SevenethAchievement: { AchieveName: 'Make a 60 Stack Tower?!?!', isCompleted: false, className: 'playStackersAchievement', value: 50000 },
      SevenethAchievement: { AchieveName: 'Make a 100 Stack Tower?!?!?!?!', isCompleted: false, className: 'playStackersAchievement', value: 100000 },
   }],
}
import React, { useEffect, useState } from "react"

import { Auth } from 'aws-amplify';
import { DataStore } from '@aws-amplify/datastore';
import { Profiles } from '../models';

import NavBar from "./NavBar"
import './SignUp.css'

// prime react components
import { InputText } from 'primereact/inputtext'
import { Password } from 'primereact/password'
import { Checkbox } from 'primereact/checkbox'
import toast, { Toaster } from 'react-hot-toast'

/* Complimentary Colors based on Main Navbar SVG color */
var backgroundColorMap = {
   0: '#CEDE9A',
   1: '#F69D7D',
   2: '#B4C2D3',
   3: '#FBAC75',
   4: '#AEBACC'
}

var backgroundColorMap_0 = {
   0: 'rgb(207, 181, 154)',
   1: 'rgb(191, 171, 141)',
   2: 'rgb(211, 195, 187)',
   3: 'rgb(191, 171, 153)',
   4: 'rgb(206, 188, 177)'
}

var backgroundColorMap_1 = {
   0: 'rgb(207, 181, 154)',
   1: 'rgb(191, 171, 141)',
   2: 'rgb(211, 195, 187)',
   3: 'rgb(191, 171, 153)',
   4: 'rgb(206, 188, 177)'
}

/* These SVG's I am switching by URL - this is probably the better way to do it, should refactor navbar's by downloading them from the dom maybe? */
/* The extra coding here gives a lot of dynamic content to the user */
var backgroundSVGMap_0 = {
   0: 'App-0-0',
   1: 'App-1-0',
   2: 'App-2-0',
   3: 'App-3-0',
   4: 'App-4-0',
}
var backgroundSVGMap_1 = {
   0: 'App-0-1',
   1: 'App-1-1',
   2: 'App-2-1',
   3: 'App-3-1',
   4: 'App-4-1',
}

let randomBGSVG = ''
let randomBGColor = ''

const SignUp = (props) => {

   const [username, setUsername] = useState(undefined)
   const [preferred_username, setGameName] = useState(undefined)
   const [email, setEmail] = useState(undefined)
   const [password, setPassword] = useState(undefined)
   const [checkbox, setCheckbox] = useState(false)

   const [isMakingAccount, setIsMakingAccount] = useState(false)

   const [showConfirmationSection, setShowConfirmationSection] = useState(false)
   const [confirmationCode, setconfirmationCode] = useState('')
   const [isConfirmingEmail, setIsConfirmingEmail] = useState(false)
   const [resendConfirmationEmailCheckbox, setResendConfirmationEmailCheckbox] = useState(false)

   useEffect(() => {
      if (randomBGColor === '') {
         // 50/50 chance to get either background svg map
         if (props.rngThemeIndexSwitch === 0) {
            randomBGColor = backgroundColorMap_0[props.themeIndex]
            randomBGSVG = backgroundSVGMap_0[props.themeIndex]
         } else {
            randomBGColor = backgroundColorMap_1[props.themeIndex]
            randomBGSVG = backgroundSVGMap_1[props.themeIndex]
         }

         document.body.style.background = randomBGColor
      }
   }, [])

   /* Based off of a single query param (only with this current implementation)
      switch component state
      - this is so we can be redirected to by the login page for example if the user needs to confirm their email
   */
   const toggleStateByQueryParams = () => {
      let currentURL = window.location.href

      let state = currentURL.split('=')[1]

      if (state == 'true') {
         setShowConfirmationSection(true)
      }
   }

   const resendConfirmationCode = async () => {
      try {
         await Auth.resendSignUp(email);
         console.log('code resent successfully');
         toast.success('Verification Code Sent!')
      } catch (err) {
         console.log('error resending code: ', err);
      }
   }

   const handleSignUp = async () => {
      try {
         setIsMakingAccount(true)
         const { user } = await Auth.signUp({
            username,
            password,
            attributes: {
               email,
               preferred_username,
            },
            autoSignIn: { // optional - enables auto sign in after user is confirmed
               enabled: true,
            },
         });
         console.log('success!')
         console.log(user);

         /*
            Also save a profile
            This is important because as of right now, there is not a way to query the real "user" table
            Creating this object lets us query it & match user to game high scores for the global account score
            Profiles are intended to be 1-1 for each user
         */
         await DataStore.save(
            new Profiles({
               "userName": preferred_username,
               "hasConfirmedEmail": false,
               "address": '',
               "age": '',
               "lastGeneratedAccountScore": 0,
               "gameHighScores": [],
               'ComputerPowerLevelMax': '',
               'ComputerPowerLevelMin': '',
               'Achievements': undefined,
               'hasAcceptedCookiePolicy': false,
               'experience': 0,
               'level': 1,
               'prestigeLevel': 0,
            })
         );

         toast.success('Account Created!', { duration: 3000 })
         setIsMakingAccount(false)
         setShowConfirmationSection(true) 
      } catch (error) {
         if (error.toString().includes('UsernameExistsException')) {
            toast.error('That email is already registered with a user. Get Lost, pal')
            setIsMakingAccount(false)
         } else {
            toast.error('Something went wrong, oops!')
            setIsMakingAccount(false)
         }
      }
   }

   const confirmSignUp = async () => {
      try {
         setIsConfirmingEmail(true)
         await Auth.confirmSignUp(email, confirmationCode);
         console.log('success! email confirmed!')
         toast.success('Email confirmed! Redirecting...', { duration: 3000 })

         try {
            if (preferred_username !== undefined) {

               // also update user profile that email is confirmed
               // note: it is possible user would not be correct if the page was refreshed
               //       if that is the case, we can skip this step and it will be an "account action" later
               const userProfile = await DataStore.query(Profiles, p => p.userName.eq(preferred_username))

               // then save a copy of the original, with the field we want changed updated
               // it should always return an array of 1 item
               await DataStore.save(Profiles.copyOf(userProfile[0], updated => updated.hasConfirmedEmail = true))
            }
         } catch (e) {
            console.log(e)
         }

         // redirect after 2 seconds
         setIsConfirmingEmail(false)
         setTimeout(() => {
            document.getElementById('backToHome').click()
         }, 2000)
      } catch (error) {
         toast.error('Something went wrong, oops! Try Again?')
         console.log('error confirming sign up', error);
      }
   }

   useEffect(() => {
      toggleStateByQueryParams()
   }, [])

   return (
      <>
         <div className={randomBGSVG}>
            <NavBar backToHome={true} />

            {/* Main Register Form */}
            {!showConfirmationSection &&

               <>
                  <div className='grid-fluid signUpGrid benefitBox d-flex justify-content-center'>
                     <div className='row infoDialog'>
                        <div className='col-12 d-flex justify-content-center'>
                           <h2>Account Beneifts</h2>
                        </div>

                        <div className="col-12 d-flex justify-content-center">
                           <div className='row'>
                              <div className='col-12'>
                                 <ul>
                                    <li>Track & Save High Scores!</li>
                                    <li>Collect Achievements! Can you get them all?</li>
                                    <li>Compete for the Top Leaderboards!</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className='grid-fluid signUpGrid d-flex justify-content-center'>
                     <div className='row registerDialog'>
                        <div className='col-12 d-flex justify-content-center mb-4 border-bottom'>
                           <h2>Register Today!</h2>
                        </div>

                        <div className="col-12 d-flex justify-content-center mt-2 mb-3">
                           <span className="p-float-label">
                              <InputText id="email" className='register-form-button' value={email} onChange={(e) => {
                                 setEmail(e.target.value)
                                 setUsername(e.target.value)
                              }} />
                              <label htmlFor="email">Email</label>
                           </span>
                        </div>

                        {/* LG TODO: Add password confirmation box */}
                        <div className="col-12 d-flex justify-content-center mt-3 mb-3">
                           <span className="p-float-label">
                              <Password toggleMask id='password' className='register-form-button' inputStyle={{ width: "100%" }} style={{ width: "100%" }} value={password} onChange={(e) => setPassword(e.target.value)} />
                              <label htmlFor="password">Password</label>
                           </span>
                        </div>

                        <div className="col-12 d-flex justify-content-center mt-3 mb-3">
                           <span className="p-float-label">
                              <InputText id='username' className='register-form-button' value={preferred_username} onChange={(e) => setGameName(e.target.value)} />
                              <label htmlFor="username">Username</label>
                           </span>
                        </div>

                        <div className="col-12 mt-4 d-flex justify-content-center">
                           <Checkbox id='checkbox' className='checkbox' onChange={e => setCheckbox(e.checked)} checked={checkbox} />
                           <label htmlFor="checkbox" className="p-checkbox-label checkbox-text">I like playing games</label>
                        </div>

                        <div className='col-12 d-flex justify-content-center mt-4 pt-3'>
                           {!isMakingAccount &&
                              <a className='signup-button text-center' onClick={handleSignUp}>Make an Account!</a>
                           }

                           {isMakingAccount &&
                              <a className='signup-button-disabled text-center'>Making Your Account...</a>
                           }
                        </div>

                     </div>
                  </div>
               </>
            }

            {/* Email Confirmation Dialog */}
            {showConfirmationSection &&
               <div className='grid-fluid signUpGrid d-flex justify-content-center'>
                  <div className='row registerDialog'>
                     <div className='col-12 d-flex justify-content-center mb-3 border-bottom'>
                        <h2>Confirm Your Email?</h2>
                     </div>

                     <div className="col-12 mt-0 mb-3 d-flex justify-content-center">
                        <Checkbox id='checkbox' className='checkbox' onChange={e => setResendConfirmationEmailCheckbox(e.checked)} checked={resendConfirmationEmailCheckbox} />
                        <label htmlFor="checkbox" className="p-checkbox-label checkbox-text">Need a new code?</label>
                     </div>

                     {/* CONFIRM EMAIL FORM */}
                     {!resendConfirmationEmailCheckbox &&
                        <>
                           <p>A verification code was sent to {email ?? 'your email'}. Check your inbox, and confirm it here!</p>

                           <div className="col-12 d-flex justify-content-center mt-3 mb-3">
                              <span className="p-float-label">
                                 <InputText id='confirmationCode' className='register-form-button' value={confirmationCode} onChange={(e) => setconfirmationCode(e.target.value)} />
                                 <label htmlFor="confirmationCode">Super Secret Email Code</label>
                              </span>
                           </div>

                           <div className='col-12 d-flex justify-content-center mt-4 pt-3'>
                              {!isConfirmingEmail &&
                                 <a className='signup-button text-center' onClick={confirmSignUp}>Send It!</a>
                              }

                              {isConfirmingEmail &&
                                 <a className='signup-button-disabled text-center'>Confirming Email...</a>
                              }
                           </div>
                        </>
                     }

                     {/* RESEND CONFIRMATION CODE FORM */}
                     {resendConfirmationEmailCheckbox &&
                        <>
                           <p>Enter the email you want your code to be sent to. This should be the one you registered your account with!</p>

                           <div className="col-12 d-flex justify-content-center mt-3 mb-3">
                              <span className="p-float-label">
                                 <InputText id='confirmationCode' className='register-form-button' value={email} onChange={(e) => setEmail(e.target.value)} />
                                 <label htmlFor="confirmationCode">Email</label>
                              </span>
                           </div>

                           <div className='col-12 d-flex justify-content-center mt-4 pt-3'>
                              {!isConfirmingEmail &&
                                 <a className='signup-button text-center' onClick={resendConfirmationCode}>Send It!</a>
                              }

                              {isConfirmingEmail &&
                                 <a className='signup-button-disabled text-center'>Re-Sending Code...</a>
                              }
                           </div>
                        </>
                     }

                  </div>
               </div>
            }
         </div>
         <Toaster />
      </>
   )
}

export default SignUp